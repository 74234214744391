import React, { useContext, useEffect, useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup'; 
import style from './Profile.module.css';
import personalLogo from '../../Assets/images/أيقون-الصفحة-الشخصية.png';
import Courses from '../../Assets/images/أيقون-الدورات.png';
import Designs from '../../Assets/images/أيقون-التصميمات.png';
import Points from '../../Assets/images/ايقون-النقاط.png';
import TotalPoints from '../../Assets/images/ايقون-اجمالي-النقاط.png';
import GalleryImage from '../../Assets/images/معرض-اعمالي.png';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { BaseUrlContext } from '../../Contexts/BaseUrlContext';
import { toast, Toaster } from 'react-hot-toast';

export default function Profile() {
  let navigate = useNavigate();
  const [categories, setCategories] = useState([]);
  const [display, setDisplay] = useState('d-none');
  const [display1, setDisplay1] = useState('d-none');
  const [overlay, setOverlay] = useState('d-none');
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [userData, setUserData] = useState(null);
  let { baseUrl, setBaseUrl } = useContext(BaseUrlContext);
  const [profileImage, setProfileImage] = useState(personalLogo);
  const [showImage, setShowImage] = useState(false);
  const [modalContent, setModalContent] = useState(null);
  const validationSchema = Yup.object({
    first_name: Yup.string().required('الاسم الأول مطلوب'),
    last_name: Yup.string().required('الاسم الأخير مطلوب'),
    email: Yup.string().email('البريد الإلكتروني غير صالح').required('البريد الإلكتروني مطلوب'),
    age: Yup.number().required('العمر مطلوب').positive('العمر يجب أن يكون رقماً موجباً'),
    gender: Yup.string().required('الجنس مطلوب'),
    job_title: Yup.string().required('المسمى الوظيفي مطلوب'),
    category_id: Yup.string().required('الفئة مطلوبة'),
    
  });
  async function verifyAccount() {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.post(`${baseUrl}api/email/verification-notification`, {}, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      
      toast.success(response.data.message);
    } catch (error) {
      toast.error(error.response?.data?.message || 'حدث خطأ أثناء محاولة تفعيل الحساب.');
    }
  }
  
  
  

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      setIsAuthenticated(true);
    }
    getData();
    fetchCategories();
  }, []);

  async function fetchCategories() {
    try {
      const { data } = await axios.get(`${baseUrl}api/categories`);
      setCategories(data.data);
    } catch (error) {
      console.error('Error fetching categories', error);
    }
  }

  async function getData() {
    try {
      const token = localStorage.getItem('token');
      const { data } = await axios.get(`${baseUrl}api/auth/user-profile`, {
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      });
      setUserData(data.data);
      if (data.data.picture) {
        setProfileImage(data.data.picture);
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        Logout();
      } else {
        console.error('Error fetching data', error);
      }
    }
  }
  
  

  async function handleProfileImageUpload(event) {
    const file = event.target.files[0];

    if (!file) return;

    const imageUrl = URL.createObjectURL(file);
    setProfileImage(imageUrl);

    const formData = new FormData();
    formData.append('picture', file);

    try {
      const token = localStorage.getItem('token');
      const response = await axios.post(`${baseUrl}api/auth/update-picture`, formData, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'multipart/form-data',
        },
      });
      toast.success('Profile picture updated successfully');
      getData();
      console.log("response", response);
    } catch (error) {
      console.error('Error uploading profile picture', error);
      toast.error('An error occurred while uploading the profile picture.');
    }
  }

  function toggleDisplay() {
    const newDisplay = display === 'd-none' ? 'd-block' : 'd-none';
    const newOverlay = overlay === 'd-none' ? 'd-block' : 'd-none';
    setDisplay(newDisplay);
    setOverlay(newOverlay);

    if (newDisplay === 'd-block') {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }
  }

  function toggleDisplay1() {
    const newDisplay1 = display1 === 'd-none' ? 'd-block' : 'd-none';
    const newOverlay = overlay === 'd-none' ? 'd-block' : 'd-none';
    setDisplay1(newDisplay1);
    setOverlay(newOverlay);

    if (newDisplay1 === 'd-none') {
      document.body.style.overflow = 'auto';
    }
  }

  async function Logout() {
    try {
      const token = localStorage.getItem('token');
      await axios.post(`${baseUrl}api/auth/logout`, {}, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      localStorage.removeItem('token');
      setIsAuthenticated(false);
      document.body.style.overflow = 'auto';
      navigate('/');
    } catch (error) {
      console.error('Error logging out', error);
      localStorage.removeItem('token');
      document.body.style.overflow = 'auto';
      navigate('/');
    }
  }

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      first_name: userData?.first_name || '',
      last_name: userData?.last_name || '',
      email: userData?.email || '',
      age: userData?.age || '',
      gender: userData?.gender || '',
      job_title: userData?.job_title || '',
      category_id: userData?.category_id || '',
      phone: userData?.phone || '',
      country: userData?.country || ''
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      try {
        const token = localStorage.getItem('token');
        await axios.put(`${baseUrl}api/auth/update-profile`, values, {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });
        toast.success('Profile updated successfully');
        toggleDisplay1();
        getData();
      } catch (error) {
        console.error('Error updating profile', error);
        toast.error('An error occurred while updating the profile.');
      }
    },
  });
  
  async function handleFileUpload(event) {
    const file = event.target.files[0];

    if (!file) return;

    const formData = new FormData();
    formData.append("title", `gallery${userData?.gallery.length + 1}`);
    formData.append("path", file);

    try {
      const token = localStorage.getItem('token');
      const response = await axios.post(`${baseUrl}api/gallery`, formData, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'multipart/form-data',
        },
      });
      
      console.log("response", response);
      toast.success('File uploaded successfully');
      getData();
      toggleDisplay();
    } catch (error) {
      console.error('Error uploading file', error);
      
      // Check if the error response has a message from the API
      if (error.response && error.response.data && error.response.data.message) {
        toast.error(error.response.data.message); 
      } else {
        toast.error(error.response.data.message); 
      }
    }
      }

  async function handleDelete(imageId) {
    try {
      const token = localStorage.getItem('token');
      await axios.delete(`${baseUrl}api/gallery/${imageId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      toast.success('File deleted successfully');
      getData();
    } catch (error) {
      console.error('Error deleting file', error);
      toast.error('An error occurred while deleting the file.');
    }
  }

  const videoTypes = ['mp4', 'avi', 'mkv', 'mov', 'wmv', 'flv', 'webm', 'mpg', 'mpeg', 'm4v', '3gp', 'ogv', 'rm', 'ts', 'vob'];

  function isVideoType(type) {
    return videoTypes.includes(type.toLowerCase());
  }

  function openModal(item) {
    setModalContent(item.url);
    setShowImage(true);
  }

  function closeResults() {
    setShowImage(false);
    setModalContent(null);
  }

  return (
    <>
      <Toaster reverseOrder={false} />
      <div className={`${style.overlay} position-fixed start-0 w-100 h-100 bg-white ${overlay}`} style={{ opacity: 0.5, zIndex: 1 }}></div>
      <div className={`${style.container1} position-absolute ${display}`} style={{ zIndex: 2, borderBottomLeftRadius: '15px' }}>
        <i className={`fa-solid fa-times text-white fs-4 position-absolute top-0 end-0 m-2 ${style.or}`} onClick={toggleDisplay}></i>
        {!isAuthenticated && <p className={`${style.para} pb-2`}><Link to={'/login'} className={`${style.para}`}>تسجيل الدخول </Link></p>}
        <p className={`${style.para} pb-2`} onClick={toggleDisplay1}><Link className={`${style.para}`}>تعديل الحساب </Link></p>
        <p className={`${style.para} pb-2`} onClick={verifyAccount}>تفعيل الحساب</p>
        <p className={`${style.para} pb-2`} onClick={() => document.getElementById('fileInput').click()}>
          <Link className={`${style.para}`}>أضافة تصميـــــم</Link>
          <input 
            type="file" 
            id="fileInput" 
            style={{ display: 'none' }} 
            onChange={handleFileUpload}
          />
        </p>
        <p className={`${style.para} pb-2`}><Link className={`${style.para}`}>الدورات التدريبية </Link></p>
        <p className={`${style.para} pb-2`}><Link to={'/Withdraw'} className={`${style.para}`}>سحب النقــــــــاطـ </Link></p>
        <p className={`${style.para} pb-2`}><Link className={`${style.para}`}>ماهي النقــــــــاط ؟ </Link></p>
        {isAuthenticated && <p className={`${style.para}`}><Link className={`${style.para}`} onClick={Logout}>تسجيل خــــــــــروج </Link></p>}
      </div>
      <div className={`${style.profile}`}>
        <div className={`${style.information} py-5`}>
          <div className="container">
            <i className={`fa-solid fa-bars text-white fs-4 ${style.or}`} onClick={toggleDisplay}></i>
          </div>
          <div className="container text-center">
            {/* <img src={personalLogo} alt="أيقون-الصفحة-الشخصية" /> */}
            <img src={profileImage} alt="أيقون-الصفحة-الشخصية"  style={{ cursor: 'pointer' }}
              onClick={() => document.getElementById('profileImageInput').click()} />
            <input
              type="file"
              id="profileImageInput"
              style={{ display: 'none' }}
              accept="image/*"
              onChange={handleProfileImageUpload}
            />
            <div className="row mt-3">
              <div className="col-xl-3 col-lg-6 mb-4 mb-xl-0">
                <p>{userData?.first_name} {userData?.last_name}</p>
              </div>
              <div className="col-xl-3 col-lg-6 mb-4 mb-xl-0">
                <p>{userData?.job_title}</p>
              </div>
              <div className="col-xl-3 col-lg-6 mb-4 mb-xl-0">
                <p>{userData?.code}</p>
              </div>
              <div className="col-xl-3 col-lg-6 mb-4 mb-xl-0">
                <p>{userData?.email}</p>
              </div>
            </div>
          </div>
        </div>
        <div className={`${style.workInformation} py-5 my-4`}>
          <div className="container">
            <div className="row">
              <div className="col-xl-3 col-lg-6 text-center">
                <div className={`${style.image} d-flex justify-content-center align-items-center`}>
                  <img src={Courses} alt="ايقون-الدورات" />
                </div>
                <h3  className={`${style.mrg}`}>الدورات</h3>
                <p>{userData?.courses_count} دورات</p>
              </div>
              <div className="col-xl-3 col-lg-6 text-center">
                <div className={`${style.image} d-flex justify-content-center align-items-center`}>
                  <img src={Designs} alt="ايقون-التصميمات" />
                </div>
                <h3  className={`${style.mrg}`}>التصميمات</h3>
                <p>{userData?.gallery.length} تصميم</p>
              </div>
              <div className="col-xl-3 col-lg-6 text-center">
                <div className={`${style.image} d-flex justify-content-center align-items-center`}>
                  <img src={Points} alt="ايقون-النقاط" className='mb-2' />
                </div>
                <h3  className={`${style.mrg}`}>النقاط</h3>
                <p>{userData?.points} نقطة</p>
              </div>
              <div className="col-xl-3 col-lg-6 text-center">
                <div className={`${style.image} d-flex justify-content-center align-items-center`}>
                  <img src={TotalPoints} alt="ايقون-اجمالي-النقاط" />
                </div>
                <h3  className={`${style.mrg}`}>أجمالي النقاط</h3>
                <p>{userData?.points*50} جنيــــة</p>
              </div>
            </div>
          </div>
        </div>
        <div className={`${style.gallery} pt-5`}>
          <div className="container pb-2">
            <h2>معرض أعمالي</h2>
          </div>
          <div className={`w-100 ${style.bgDark} py-3`}>
            <div className="container pb-5">
              <div className="row pb-5">
              {userData?.gallery && userData.gallery.length > 0 ? (
                userData.gallery.map((item) => (
                  <div key={item.id} className="col-lg-4 col-md-6 pt-4 position-relative">
                    <div className={`${style.project} position-relative`}>
                    {isVideoType(item.type) ?(
                      <>
                        <video autoPlay playsInline loop muted className="video-element" src={item.url} />
                        <div className={`${style.overlay} position-absolute start-0 w-100 h-100 d-flex justify-content-center align-items-center gap-2`} >
                          <button className="btn btn-danger" onClick={() => handleDelete(item.id)} >
                            <i class="fa-solid fa-trash-can"></i>
                          </button>
                          <button className="btn btn-danger" onClick={() => openModal(item)}>
                            <i class="fa-solid fa-circle-play"></i>
                          </button>
                        </div>
                      </>
                      ) : (
                      <>
                        <img src={item.url} className="w-100 h-100" alt={item.title} />
                        <div className={`${style.overlay} position-absolute start-0 w-100 h-100 d-flex justify-content-center align-items-center gap-2`} >
                          <button className="btn btn-danger" onClick={() => handleDelete(item.id)} >
                            <i class="fa-solid fa-trash-can"></i>
                          </button>
                          <button className="btn btn-danger" onClick={() => openModal(item)}>
                            <i class="fa-solid fa-magnifying-glass-plus"></i>
                          </button>
                        </div>
                      </>
                      )}
                    </div>
                  </div>
                ))
              ) : (
                <>
                  <div className="col-lg-4 col-md-6 pt-4">
                    <div className={`${style.project}`}>
                      <img src={GalleryImage} alt="معرض-اعمالي" />
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-6 pt-4">
                    <div className={`${style.project}`}>
                      <img src={GalleryImage} alt="معرض-اعمالي" />
                    </div>
                  </div>
                </>
              )}
              </div>
            </div>
          </div>
        </div>
      </div>
      {showImage && (
        <div className={style.bgCover}>
          <div className={style.resultsModal}>
            <button className={style.closeButton} onClick={closeResults}>×</button>
            <div className='w-100 d-flex justify-content-center align-items-center'>
              {modalContent && (modalContent.endsWith('.mp4') || modalContent.endsWith('.avi') || modalContent.endsWith('.mov')) ? (
                <video controls className="w-100">
                  <source src={modalContent} type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
              ) : (
                <img src={modalContent} alt="Modal content" className="w-100" />
              )}
            </div>
          </div>
        </div>
      )}

      <div className={`${display1} ${style.dis} position-absolute top-50 start-50 translate-middle`} style={{ zIndex: 3, background: 'white', padding: '20px', borderRadius: '10px', boxShadow: '0 0 10px rgba(0,0,0,0.1)' }}>
        <form onSubmit={formik.handleSubmit} className={style.form} style={{direction:"rtl"}}>
        <div className={style.formField}>
          <input 
            type="text" 
            name="first_name" 
            value={formik.values.first_name} 
            onChange={formik.handleChange} 
            placeholder="الاسم الأول" 
            className={`${style.input} ${formik.errors.first_name && formik.touched.first_name ? style.errorBorder : ''}`} 
          />
          {formik.errors.first_name && formik.touched.first_name ? (
            <div className={style.errorMessage}>{formik.errors.first_name}</div>
          ) : null}
        </div>

        <div className={style.formField}>
          <input 
            type="text" 
            name="last_name" 
            value={formik.values.last_name} 
            onChange={formik.handleChange} 
            placeholder="الاسم الأخير" 
            className={`${style.input} ${formik.errors.last_name && formik.touched.last_name ? style.errorBorder : ''}`} 
          />
          {formik.errors.last_name && formik.touched.last_name ? (
            <div className={style.errorMessage}>{formik.errors.last_name}</div>
          ) : null}
        </div>

        <div className={style.formField}>
          <input 
            type="email" 
            name="email" 
            value={formik.values.email} 
            onChange={formik.handleChange} 
            placeholder="البريد الإلكتروني" 
            className={`${style.input} ${formik.errors.email && formik.touched.email ? style.errorBorder : ''}`} 
          />
          {formik.errors.email && formik.touched.email ? (
            <div className={style.errorMessage}>{formik.errors.email}</div>
          ) : null}
        </div>

        <div className={style.formField}>
          <input 
            type="number" 
            name="age" 
            value={formik.values.age} 
            onChange={formik.handleChange} 
            placeholder="العمر" 
            className={`${style.input} ${formik.errors.age && formik.touched.age ? style.errorBorder : ''}`} 
          />
          {formik.errors.age && formik.touched.age ? (
            <div className={style.errorMessage}>{formik.errors.age}</div>
          ) : null}
        </div>

        <div className={style.formField}>
          <select 
            name="gender" 
            value={formik.values.gender} 
            onChange={formik.handleChange} 
            className={`${style.select} ${formik.errors.gender && formik.touched.gender ? style.errorBorder : ''}`}
          >
            <option value="">الجنس</option>
            <option value="male">ذكر</option>
            <option value="female">أنثى</option>
          </select>
          {formik.errors.gender && formik.touched.gender ? (
            <div className={style.errorMessage}>{formik.errors.gender}</div>
          ) : null}
        </div>

        <div className={style.formField}>
          <input 
            type="text" 
            name="job_title" 
            value={formik.values.job_title} 
            onChange={formik.handleChange} 
            placeholder="المسمى الوظيفي" 
            className={`${style.input} ${formik.errors.job_title && formik.touched.job_title ? style.errorBorder : ''}`} 
          />
          {formik.errors.job_title && formik.touched.job_title ? (
            <div className={style.errorMessage}>{formik.errors.job_title}</div>
          ) : null}
        </div>

        <div className={style.formField}>
          <select
            name="category_id"
            value={formik.values.category_id}
            onChange={formik.handleChange}
            className={`${style.select} ${formik.errors.category_id && formik.touched.category_id ? style.errorBorder : ''}`}
          >
            <option value="">اختار الفئة</option>
            {categories.map(category => (
              <option key={category.id} value={category.id}>
                {category.name}
              </option>
            ))}
          </select>
          {formik.errors.category_id && formik.touched.category_id ? (
            <div className={style.errorMessage}>{formik.errors.category_id}</div>
          ) : null}
        </div>
          
          <div className={style.formField}>
            <input 
              type="text" 
              name="country" 
              value={formik.values.country} 
              onChange={formik.handleChange} 
              placeholder="الدولة (اختياري)" 
              className={style.input}
            />
          </div>
          <div className={style.formField}>
            <button type="submit" className={style.submitButton}>تحديث</button>
          </div>
          <div className={style.formField}>
            <button type="" onClick={toggleDisplay1} className={`${style.submitButton1} bg-danger`}>إغلاق</button>
          </div>
        </form>
      </div>

     
    </>
  );
}
