import React, { useContext, useState } from 'react';
import axios from 'axios';
import style from './Who.module.css';
import email from '../../Assets/images/email.PNG'
import location from '../../Assets/images/location.PNG'
import { height } from '@fortawesome/free-solid-svg-icons/fa0';
import { BaseUrlContext } from '../../Contexts/BaseUrlContext';
import { toast, Toaster } from 'react-hot-toast';

export default function Who() {
  const [inquiryData, setInquiryData] = useState({
    name: '',
    email: '',
    phone: '',
    message: ''
  });
  const { baseUrl } = useContext(BaseUrlContext);

  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const handleChange = (e) => {
    setInquiryData({
      ...inquiryData,
      [e.target.name]: e.target.value
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    axios.post(`${baseUrl}api/send-inquiry`, inquiryData)
      .then(response => {
        setSuccessMessage('تم إرسال الاستفسار بنجاح');
        setErrorMessage('');
        setInquiryData({ name: '', email: '', phone: '', message: '' });
      })
      .catch(error => {
        setErrorMessage('حدث خطأ أثناء إرسال الاستفسار. حاول مرة أخرى.');
        setSuccessMessage('');
      });
  };

  return (
    <>
      <div className={`${style.container1} mb-5`}>
        <p className={`${style.para}`}>من نــــــــــــحن</p>
        <p className={`${style.para1} mb-5`}>نحــــــن أكاديمية تعليـــــم الجرافيـــــــك، ونهدف إلى توفيـــــــر تجربة تعليميـــة شاملة واحترافية في مجال الجرافيك للمهتمــــيـــن والمبتدئين والمحترفين على حد سواء نحن ملتزمون بتقديم محتــــــــوى تعليمــــي متميز ومبتكر يغطي جوانـب متعددة في عالم الجرافيك .</p>
        <p className={`${style.par}`}>فريقنا مكون من خبراء ومحترفين في مجالات متنوعة مثل التصميـم الجرافيكي وتصميم الشعارات، والرسوم المتحركة، وتصميـــم الويب، وتصميم الطبـــــــاعة وغيرهــــا. نحن نجمــــــــع بين المعرفة النظريــــــة والمهــــــارات العمليــة لنقدم للطلاب</p>
        <p className={`${style.par} mb-5`}>والمتدربين تجربة تعليمية مثيرة ومفيدة</p>
        <div className='mt-5'>
          <p className={`${style.par}`}>تتميز أكاديميتنا ببرامج تعليميــــــة شاملة ومنهجية مبنيـــــــة علـــى أحدث التقنيــــــات والاتجاهــــــات في مجــــــال الجرافيك.</p>
          <p className={`${style.par}`}>نحن نوفر مجموعة متنوعة من الدروس والدورات التعليمية التفاعلية التي تغطي جميع المستــــويات، بدءا من المبتدئي</p>
          <p className={`${style.par}`}>وصــــــولًا  إلى المحترفــــيـــــن، مما يتيح للمتعلميــــــن تطوير مهاراتهم وتحقيـــــق إبداعاتهم الخاصــــة فــــــــي مجال الجرافيك.</p>
        </div>
        <p className={`${style.par} mt-5`}>بغض النظر عن خلفيتـــــــك أو مستوى خبرتـــــك، فإننا نسعـــــــــــى جاهدين لتوفير يئة تعليمية مشجعة وداعمة تمكنك من تحقيق أهدافك في عالم الجرافيك. نحن نهدف إلى تزويدك بالمعرفـــــــة والمـــــــهارات اللازمة للتميز في مجــــال الجرافيك وتحويل شغفك إلى حقيقة ملموسة.</p>
        <p className={`${style.par3} ${style.par} mt-5`}>انضــــــــــــــــم إلينا اليوم واستعــــــــــد لاكتشاف عالــــــــــم الجرافيــــــــــــك، والتعلم من خبرائنــــــــا، وتوسيع آفاقـــــــــك الإبداعية. نحن هنا لمساعدتك في رحلتك التعليمية والمهنية في مجال الجرافيك.</p>
      </div>
      <div className={`${style.container2} `} style={{marginTop:"20%"}} >
        <div className="container">
          <div className="row">
            <div className={`col-md-6 ${style.kn}`} style={{paddingRight:'11rem'}}>
            <p className='text-end'><img src={location} alt="" /></p>
              <p className={`${style.location}`}>العـــــــــنوان</p>
              <p className={`${style.location1}`}>محافظة - الجيزة - ٦ أكتوبر - المحور المركزي - الحي العاشر</p>
              <p className={`${style.location1}`}>بجوار سيلفر مول - ETE  اكاديمية</p>
            </div>
            <div className={`col-md-5 ${style.kn2}`} style={{paddingRight:'4rem'}}>
            <p className='text-end'><img src={email} alt="" style={{height:"95px"}}/></p>
              <p className={`${style.location}`}>البريـــــد الإلكترونـــــي</p>
              <p className={`${style.location1}`}>etqanacademy29@gmail.com</p>
            </div>
          </div>
        </div>
      </div>
      <div className={`${style.container3}`}>
      {successMessage && <p className={`${style.successMessage}`}>{successMessage}</p>}
      {errorMessage && <p className={`${style.errorMessage}`}>{errorMessage}</p>}
        <div className="container">
          <div className={`${style.formTitle} my-5`}>أرسل استفسارك</div>
          <form onSubmit={handleSubmit}>
            <div className={`${style.formGroup}`}>
              <input
                type="text"
                className={`${style.formControl} my-3`}
                placeholder="الاسم بالكامل"
                name="name"
                value={inquiryData.name}
                onChange={handleChange}
              />
            </div>
            <div className={`${style.formGroup} my-3`}>
              <input
                type="email"
                className={`${style.formControl} my-3`}
                placeholder="البريد الإلكتروني"
                name="email"
                value={inquiryData.email}
                onChange={handleChange}
              />
            </div>
            <div className={`${style.formGroup} my-3`}>
              <input
                type="text"
                className={`${style.formControl} my-3`}
                placeholder="رقم الهاتف"
                name="phone"
                value={inquiryData.phone}
                onChange={handleChange}
              />
            </div>
            <div className={`${style.formGroup} my-3`}>
              <textarea
                className={`${style.formControl} my-3`}
                rows="4"
                placeholder="الرسالة *"
                name="message"
                value={inquiryData.message}
                onChange={handleChange}
              ></textarea>
            </div>
            <button type="submit" className={`${style.btnSubmit} mt-3 mb-5`}>
            أرســـــل
            </button>
          </form>
        </div>
      </div>
    </>
  );
}
